// GameList.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import game1Data from '../games/game1.json';
import game2Data from '../games/game2.json';
import game3Data from '../games/game3.json';
import game4Data from '../games/game4.json';

const GameList = () => {
  return (
    <div className='game-list-container'>
      <div className="book-container"> {/* Apply book-container class */}
        <h1>Imperiets fortelling</h1>
        <div className="navigation">
          {/* Add navigation buttons here if needed */}
        </div>
        <div className="page">
          <Link to="/games/game1" className="page-content">{game1Data.title}</Link> {/* Apply page-content class */}
        </div>
        <div className="page">
          <Link to="/games/game2" className="page-content">{game2Data.title}</Link> {/* Apply page-content class */}
        </div>
        <div className="page">
          <Link to="/games/game3" className="page-content">{game3Data.title}</Link> {/* Apply page-content class */}
        </div>
        <div className="page">
          <Link to="/games/game4" className="page-content">{game4Data.title}</Link> {/* Apply page-content class */}
        </div>
      </div>
    </div>
  );
};

export default GameList;
