// GamePage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../styles.css'; // Import the styles

const GamePage = () => {
  const { id } = useParams();
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    // Dynamically import the game data based on the id
    import(`../games/${id}.json`)
      .then((data) => setGameData(data))
      .catch((error) => console.error('Error loading game data:', error));
  }, [id]);  
  if (!gameData) {
    return <div>Loading...</div>;
  }
  return (
    <div className='game-page-container'>
      <div className="book-container">
        <div className="character-box">
          {gameData.characters.map((character, index) => (
            <div key={index} className={`character ${character.name.toLowerCase()}`}>
              <strong>{character.name}</strong>: {character.player}
            </div>
          ))}
        </div>
        <div className="page">
          <div className="page-content">
            <h1>{gameData.title}</h1>
            <p>{gameData.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePage;
